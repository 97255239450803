import _core from "../core";
var exports = {};

var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __exportStar = exports && exports.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CancelError = exports.ParseError = void 0;
const core_1 = _core;
/**
An error to be thrown when server response code is 2xx, and parsing body fails.
Includes a `response` property.
*/

class ParseError extends core_1.RequestError {
  constructor(error, response) {
    const {
      options
    } = response.request;
    super(`${error.message} in "${options.url.toString()}"`, error, response.request);
    this.name = "ParseError";
    this.code = this.code === "ERR_GOT_REQUEST_ERROR" ? "ERR_BODY_PARSE_FAILURE" : this.code;
  }

}

exports.ParseError = ParseError;
/**
An error to be thrown when the request is aborted with `.cancel()`.
*/

class CancelError extends core_1.RequestError {
  constructor(request) {
    super("Promise was canceled", {}, request);
    this.name = "CancelError";
    this.code = "ERR_CANCELED";
  }

  get isCanceled() {
    return true;
  }

}

exports.CancelError = CancelError;

__exportStar(_core, exports);

export default exports;