import _types from "./types";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const types_1 = _types;

const parseBody = (response, responseType, parseJson, encoding) => {
  const {
    rawBody
  } = response;

  try {
    if (responseType === "text") {
      return rawBody.toString(encoding);
    }

    if (responseType === "json") {
      return rawBody.length === 0 ? "" : parseJson(rawBody.toString());
    }

    if (responseType === "buffer") {
      return rawBody;
    }

    throw new types_1.ParseError({
      message: `Unknown body type '${responseType}'`,
      name: "Error"
    }, response);
  } catch (error) {
    throw new types_1.ParseError(error, response);
  }
};

exports.default = parseBody;
export default exports;