import * as _is2 from "@sindresorhus/is";

var _is = "default" in _is2 ? _is2.default : _is2;

var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const is_1 = _is;

function deepFreeze(object) {
  for (const value of Object.values(object)) {
    if (is_1.default.plainObject(value) || is_1.default.array(value)) {
      deepFreeze(value);
    }
  }

  return Object.freeze(object);
}

exports.default = deepFreeze;
export default exports;