import * as _url2 from "url";

var _url = "default" in _url2 ? _url2.default : _url2;

import _create from "./create";
import _asPromise from "./as-promise";
var exports = {};

var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __exportStar = exports && exports.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
const url_1 = _url;
const create_1 = _create;
const defaults = {
  options: {
    method: "GET",
    retry: {
      limit: 2,
      methods: ["GET", "PUT", "HEAD", "DELETE", "OPTIONS", "TRACE"],
      statusCodes: [408, 413, 429, 500, 502, 503, 504, 521, 522, 524],
      errorCodes: ["ETIMEDOUT", "ECONNRESET", "EADDRINUSE", "ECONNREFUSED", "EPIPE", "ENOTFOUND", "ENETUNREACH", "EAI_AGAIN"],
      maxRetryAfter: undefined,
      calculateDelay: ({
        computedValue
      }) => computedValue
    },
    timeout: {},
    headers: {
      "user-agent": "got (https://github.com/sindresorhus/got)"
    },
    hooks: {
      init: [],
      beforeRequest: [],
      beforeRedirect: [],
      beforeRetry: [],
      beforeError: [],
      afterResponse: []
    },
    cache: undefined,
    dnsCache: undefined,
    decompress: true,
    throwHttpErrors: true,
    followRedirect: true,
    isStream: false,
    responseType: "text",
    resolveBodyOnly: false,
    maxRedirects: 10,
    prefixUrl: "",
    methodRewriting: true,
    ignoreInvalidCookies: false,
    context: {},
    // TODO: Set this to `true` when Got 12 gets released
    http2: false,
    allowGetBody: false,
    https: undefined,
    pagination: {
      transform: response => {
        if (response.request.options.responseType === "json") {
          return response.body;
        }

        return JSON.parse(response.body);
      },
      paginate: response => {
        if (!Reflect.has(response.headers, "link")) {
          return false;
        }

        const items = response.headers.link.split(",");
        let next;

        for (const item of items) {
          const parsed = item.split(";");

          if (parsed[1].includes("next")) {
            next = parsed[0].trimStart().trim();
            next = next.slice(1, -1);
            break;
          }
        }

        if (next) {
          const options = {
            url: new url_1.URL(next)
          };
          return options;
        }

        return false;
      },
      filter: () => true,
      shouldContinue: () => true,
      countLimit: Infinity,
      backoff: 0,
      requestLimit: 10000,
      stackAllItems: true
    },
    parseJson: text => JSON.parse(text),
    stringifyJson: object => JSON.stringify(object),
    cacheOptions: {}
  },
  handlers: [create_1.defaultHandler],
  mutableDefaults: false
};
const got = create_1.default(defaults);
exports.default = got; // For CommonJS default export support

exports = got;
exports.default = got;
exports.__esModule = true; // Workaround for TS issue: https://github.com/sindresorhus/got/pull/1267

__exportStar(_create, exports);

__exportStar(_asPromise, exports);

export default exports;
export const __esModule = exports.__esModule,
      defaultHandler = exports.defaultHandler,
      CancelError = exports.CancelError,
      ParseError = exports.ParseError,
      UnsupportedProtocolError = exports.UnsupportedProtocolError,
      ReadError = exports.ReadError,
      TimeoutError = exports.TimeoutError,
      UploadError = exports.UploadError,
      CacheError = exports.CacheError,
      HTTPError = exports.HTTPError,
      MaxRedirectsError = exports.MaxRedirectsError,
      RequestError = exports.RequestError,
      setNonEnumerableProperties = exports.setNonEnumerableProperties,
      knownHookEvents = exports.knownHookEvents,
      withoutBody = exports.withoutBody,
      kIsNormalizedAlready = exports.kIsNormalizedAlready;