import _types from "./types";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const types_1 = _types;

function createRejection(error, ...beforeErrorGroups) {
  const promise = (async () => {
    if (error instanceof types_1.RequestError) {
      try {
        for (const hooks of beforeErrorGroups) {
          if (hooks) {
            for (const hook of hooks) {
              // eslint-disable-next-line no-await-in-loop
              error = await hook(error);
            }
          }
        }
      } catch (error_) {
        error = error_;
      }
    }

    throw error;
  })();

  const returnPromise = () => promise;

  promise.json = returnPromise;
  promise.text = returnPromise;
  promise.buffer = returnPromise;
  promise.on = returnPromise;
  return promise;
}

exports.default = createRejection;
export default exports;